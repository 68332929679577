"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractorProfileInfoData = void 0;
var constants_1 = require("../../../../constants");
var get_contractor_type_name_1 = require("./get-contractor-type-name");
var contractorProperties = [
    { label: 'Тип контрагента', id: 'types' },
    { label: 'Адрес', id: 'businessAddress' },
    { label: 'ИНН', id: 'inn' },
    { label: 'ОГРН', id: 'ogrn' },
    { label: 'КПП', id: 'kpp' },
];
var getContractorProfileInfoData = function (values) {
    var data = {
        icons: [],
        values: [],
    };
    if (!values) {
        return data;
    }
    if (values.isBlocked) {
        data.icons.push(constants_1.IconInfoType.BlockedExpert);
    }
    if (values.isBlockedByRiskFactor) {
        data.icons.push(constants_1.IconInfoType.BlockedByRiskFactor);
    }
    if (values.unscrupulousExpertOrganization) {
        data.icons.push(constants_1.IconInfoType.BlockedContractor);
    }
    contractorProperties.forEach(function (_a) {
        var _b;
        var id = _a.id, label = _a.label;
        var tmpValue = (_b = values[id]) !== null && _b !== void 0 ? _b : '';
        var value = Array.isArray(tmpValue) ? (0, get_contractor_type_name_1.getContractorTypeNames)(tmpValue) : tmpValue;
        if (value) {
            data.values.push({
                id: id,
                label: label,
                value: value,
            });
        }
    });
    return data;
};
exports.getContractorProfileInfoData = getContractorProfileInfoData;
