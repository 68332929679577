"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourtCasesColumns = void 0;
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var getCourtCasesColumns = function () {
    return [
        {
            field: 'courtCaseNumber',
            filterable: false,
            flex: 200,
            headerName: '№ дела',
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'fixedAmountSum',
            filterable: false,
            flex: 200,
            headerName: 'Сумма требований',
            minWidth: 180,
            sortable: false,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedNumber)(params.value); },
        },
        {
            field: 'requirementKindName',
            filterable: false,
            flex: 200,
            headerName: 'Вид требования',
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'roleParticipant',
            filterable: false,
            flex: 200,
            headerName: 'Роль',
            minWidth: 140,
            sortable: false,
        },
        {
            field: 'hearingResult',
            filterable: false,
            flex: 300,
            headerName: 'Итог судебного заседания',
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'decisionTakenInFavor',
            filterable: false,
            flex: 300,
            headerName: 'Принято решение в сторону',
            minWidth: 220,
            sortable: false,
            renderCell: function (params) { return params === null || params === void 0 ? void 0 : params.value; },
        },
        {
            field: 'resolutionPart',
            filterable: false,
            flex: 300,
            headerName: 'Резолютивная часть',
            minWidth: 220,
            sortable: false,
        },
        {
            field: 'caseDate',
            filterable: false,
            flex: 200,
            headerName: 'Дата начала',
            minWidth: 140,
            sortable: false,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
        {
            field: 'closingDate',
            filterable: false,
            flex: 200,
            headerName: 'Дата окончания',
            minWidth: 140,
            sortable: false,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
    ];
};
exports.getCourtCasesColumns = getCourtCasesColumns;
