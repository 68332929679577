"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessTypeCode = exports.DateFormats = exports.BOOLEAN_VALUE_MAP = exports.EMPTY_ARR = void 0;
__exportStar(require("./settings"), exports);
__exportStar(require("./texts"), exports);
exports.EMPTY_ARR = [];
exports.BOOLEAN_VALUE_MAP = {
    true: 'Да',
    false: 'Нет',
};
var DateFormats;
(function (DateFormats) {
    DateFormats["Date"] = "L";
    DateFormats["DateTime"] = "L HH:mm";
    DateFormats["DateTimeSeconds"] = "L HH:mm:ss";
    DateFormats["ShortTextDate"] = "ll";
    DateFormats["ShortTextDateTime"] = "lll";
    DateFormats["FullTextDate"] = "LL";
    DateFormats["FullTextDateTime"] = "LLL";
    DateFormats["Iso"] = "YYYY-MM-DD";
    DateFormats["Time"] = "HH:mm";
    DateFormats["TimeSeconds"] = "HH:mm:ss";
})(DateFormats = exports.DateFormats || (exports.DateFormats = {}));
var ProcessTypeCode;
(function (ProcessTypeCode) {
    ProcessTypeCode["Familiarization"] = "familiarization";
    ProcessTypeCode["Verdict"] = "verdict";
})(ProcessTypeCode = exports.ProcessTypeCode || (exports.ProcessTypeCode = {}));
