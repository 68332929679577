"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractorTypeNames = exports.getContractorTypeName = void 0;
var constants_1 = require("../constants");
var getContractorTypeName = function (contractorType) {
    if (contractorType) {
        switch (contractorType) {
            case constants_1.ContractorTypes.Contractor:
                return 'Подрядчик';
            case constants_1.ContractorTypes.Expert:
                return 'Эксперт';
            case constants_1.ContractorTypes.Organization:
                return 'Организация';
            case constants_1.ContractorTypes.Other:
                return 'Прочее';
            default:
                return 'Неизвестный тип';
        }
    }
    else {
        return '';
    }
};
exports.getContractorTypeName = getContractorTypeName;
var getContractorTypeNames = function (types) {
    var contractorTypes = [];
    types === null || types === void 0 ? void 0 : types.forEach(function (value) {
        var type = (0, exports.getContractorTypeName)(value);
        if (type != '') {
            contractorTypes.push(type);
        }
    });
    return contractorTypes.join(',');
};
exports.getContractorTypeNames = getContractorTypeNames;
